import React, {useState, useEffect} from 'react';
import './App.css';
import Header from './componentes/Header';
import FormularioTareas from './componentes/FormularioTareas';
import ListaTareas from './componentes/ListaTareas';

// Aca se encuentra las tareas y el estado 
const App = () => {
   // Ahora se borrara este arreglo ya que se debe realiza en forma Dinamica
  //const [tareas, cambiarTareas] = useState(
    // Cada tarea es un Objeto, donde se puede acceder a cada valor id, texto, etc
    //[
    //  { id: 1,
    //    texto: 'Lavar la ropa',
    //    completada: false
    //  },
    //  {id: 2,
    //    texto: 'Grabar Tutorial',
    //    completada: true
    //  }
    //]
  //);

  // Esta validando si hay datos en el LocalStorage para que lo muestre 
  // de lo contrario envia un arreglo vacio.
  // Con el parse vuelve a transformar la cadena de texto a un arreglo
  // Obtenemos las tareas guardadas de localstorage
  const tareasGuardadas = 
  localStorage.getItem('tareas') ? 
  JSON.parse(localStorage.getItem('tareas')) : [];
  //console.log(tareasGuardadas);

  // Establecemos el estado de las tareas.
  const [tareas, cambiarTareas] = useState(tareasGuardadas);

  // Guardando el esatdo dentro de localstorage
  useEffect(() => {
    //console.log('Las tareas cambiaron !!');
    // setItem es un Metodo
    // stringify = Permite transformar todo a una cadena de texto con fomrato JSON, se puede ver en Console
    localStorage.setItem('tareas', JSON.stringify(tareas));
    //console.log(JSON.stringify(tareas));
  }, [tareas]);


  // Accedemos a localstorage y comprobamos si mostrarcompletados es null
  // Se crea una variable con let, porque esta va a cambiar
  let configMostrarCompletadas = '';
  if(localStorage.getItem('mostrarCompletadas') === null){
    configMostrarCompletadas = true;
  } else {
    configMostrarCompletadas = localStorage.getItem('mostrarCompletadas') === 'true';
  }

  // Se crea nuevo Estado para realizar la accion de mostrar o no las tareas completadas
  // Tambien se debe guardar en localstorage para que no muestre al refresacr el navegador
  // El estado de mostrarCompletadas
  const [mostrarCompletadas, cambiarMostrarCompletadas] = useState(configMostrarCompletadas);
  // Se crea este Estado, para que solo muestre las No Completadas, es decir, cambie el esatdo de mostrarCompletadas
  // Y con toString permite transfomar a una cadena de texto.
  useEffect(() => {
    localStorage.setItem('mostrarCompletadas', mostrarCompletadas.toString());
  }, [mostrarCompletadas]);

  console.log(tareas);

  return (
    <div className="contenedor">
      {/**
       * Se coloca estas funciones para poder accionar el Boton "No Mostrar Completadas"
       * y se definen en el archivo Header.js
       */}
      <Header 
        mostrarCompletadas={mostrarCompletadas} 
        cambiarMostrarCompletadas={cambiarMostrarCompletadas}
      />
      <FormularioTareas tareas={tareas} cambiarTareas={cambiarTareas}/>
      {/**Se debe hacer el llamado de tareas, para poder mostrarla en la ListaTreas 
       * Y ademas se incluye la funcion cambiarTareas, para que pueda ser modificada
       * la tarea que se ingrese por el formulario.*/}

      <ListaTareas 
        tareas={tareas} 
        cambiarTareas={cambiarTareas}
        mostrarCompletadas={mostrarCompletadas}
      />
    </div>
  );
}

export default App;
