import React from 'react';
import Tarea from './Tarea';

const ListaTareas = ({tareas, cambiarTareas, mostrarCompletadas}) => {
    // Se crea otra funcion
    const toggleCompletada = (id) => {
        //console.log('Estamos editando la tarea del id ', id );
        // Se esta recorriendo el arreglo con la funcion map()
        cambiarTareas(tareas.map((tarea) => {
            //console.log('Ejecutamos codigo por cada tarea')
            if(tarea.id === id){
                // Aca se devuelve cada valor de tarea, y que tarea es un objeto; por lo tanto de devuelve 
                // un objeto
                // ...tarea; siginifica que devuelve todas las propiedades de tarea
                // y el signo "!" indica que devuelva lo contrario de lo que viene.
                return {...tarea, completada: !tarea.completada}
            }
            return tarea;
        }));
    }

    // Se crea otra funcion
    const editarTarea = (id, nuevoTexto) => {
        //console.log('Estamos editando la tarea del id ', id );
        // Se esta recorriendo el arreglo con la funcion map()
        cambiarTareas(tareas.map((tarea) => {
            //console.log('Ejecutamos codigo por cada tarea')
            if(tarea.id === id){
                // Aca se devuelve cada valor de tarea, y que tarea es un objeto; por lo tanto de devuelve 
                // un objeto
                // ...tarea; siginifica que devuelve todas las propiedades de tarea
                // y el valor texto tendra el nuevo valor que viene en la variable nuevoTexto
                return {...tarea, texto: nuevoTexto}
            }
            return tarea;
        }));
    }

    const borrarTarea = (id) => {
        //console.log('Estamos editando la tarea del id ', id );
        // Se recorre el arreglo con "filter" para que nos devuelva la lsita de lo que no
        // se quiere eliminar
        cambiarTareas(tareas.filter((tarea) => {
            //console.log('Ejecutamos codigo por cada tarea')
            if(tarea.id !== id){
                // Si la tarea es diferente "!==" solo devuelve la tarea
                return tarea;
            }
            return false;
        }));
    }

    return (
        <ul className="lista-tareas">
            {/**Para recorrer la lista de tareas se debe hacer con la funcion map() */}
            {/**Con tareas.length valida si tiene tareas */}
            {tareas.length > 0 ? tareas.map((tarea) => {
                // Se incluye IF para validar cuando deje de mostrar las tareas completadas
                // Este if muestra todas las tareas
                if(mostrarCompletadas){
                    return <Tarea 
                                key={tarea.id} 
                                tarea={tarea}
                                // Se define la funcion para que pueda ser llamada de Tarea.js
                                toggleCompletada={toggleCompletada}
                                editarTarea={editarTarea}
                                borrarTarea={borrarTarea}
                            />
                // Si la tarea no esta completada, la devolvemos.
                } else if(!tarea.completada){
                    return <Tarea 
                                key={tarea.id} 
                                tarea={tarea}
                                // Se define la funcion para que pueda ser llamada de Tarea.js
                                toggleCompletada={toggleCompletada}
                                editarTarea={editarTarea}
                                borrarTarea={borrarTarea}
                            />
                }
                // Si ya esta completada no la devolvemos
                return false;
            })
            : <div className="lista-tareas__mensaje">** No Hay Tareas Agregadas **</div>
            }
        </ul>
    );
}

export default ListaTareas;