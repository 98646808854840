import React, {useState} from 'react';
// Insertar el componente FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Importando dos tipos de iconos
import { faCheckSquare, faSquare, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';


// En este componente se esta cargando la lista de tarea que viene retornado de ListaTarea.js
// y a la vez definido del archvo App.js
// Tambien se importa la funcion toggleCompletada, editarTarea, borrarTarea que fue creada en archivo 
// ListaTareas.js en el tag <Tarea />
const Tarea = ({tarea, toggleCompletada, editarTarea, borrarTarea}) => {
    const handleSubmit = (e) => {
        // Se previene el comportamineto por defecto del navegador
        e.preventDefault();
        // Se crea esta funcion para que pueda modificar la tarea cuando presione el 
        // Boton Actualziar
        editarTarea(tarea.id, nuevaTarea);
        // Con esto sacaria el formulario del Boton Actualizar y retorna al
        // primer formulario con valor inicial o modificado
        cambiarEditandoTarea(false);
    }

    // Al colocar false no aparece el texto Actualizar
    const [editandoTarea, cambiarEditandoTarea] = useState(false);

    // Se agrega un nuevo Estado
    const [nuevaTarea, cambiarNuevaTarea] = useState(tarea.texto);

    // Accediendo al objeto de tarea que esta en App.js
    return(
        <li className="lista-tareas__tarea">
            <FontAwesomeIcon 
                //icon={faCheckSquare}
                icon={tarea.completada ? faCheckSquare : faSquare}
                className="lista-tareas__icono lista-tareas__icono-check"
                // Ejecute una funcion para dejar como completada
                // y esta funcion se Crea en el componenete padre ListaTarea.js
                onClick={() => toggleCompletada(tarea.id)}
            />
            <div className="lista-tareas__texto">
                {editandoTarea ?
                // Se crea la funcion handleSubmit, para que pueda dar enter
                // o presionar el boton dentro del formulario
                <form action="" className="formulario-editar-tarea" onSubmit={handleSubmit}>
                    <input
                        type="texto"
                        className="formulario-editar-tarea__input"
                        value={nuevaTarea}
                        // Para modificar la tarea se crea una nueva funcion
                        onChange={(e) => cambiarNuevaTarea(e.target.value)}
                    />
                    <button 
                        type="submit"
                        className="formulario-editar-tarea__btn"
                    >
                        Actualizar
                    </button>
                </form>
                : tarea.texto
                }
            </div>
           <div className="lista-tareas__contenedor-botones">
           <FontAwesomeIcon 
                    icon={faEdit}
                    className="lista-tareas__icono lista-tareas__icono-accion"
                    // con solo poner el signo "!", le indnica que debe colocar el valor 
                    // contrario de lo que viene desde la funcion estado (useState)
                    onClick={() => cambiarEditandoTarea(!editandoTarea)}
                />
                <FontAwesomeIcon 
                    icon={faTimes}
                    className="lista-tareas__icono lista-tareas__icono-accion"
                    // Esta funcion se Crea en el componenete padre ListaTarea.js
                    // Para eliminar la tarea se crea una nueva funcion
                    onClick={() => borrarTarea(tarea.id)}
                />
           </div>
        </li>
    );
}

export default Tarea;