import React from 'react';
// Insertar el componente FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Importando dos tipos de iconos
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';

const Header = ({mostrarCompletadas, cambiarMostrarCompletadas}) => {
    const toggleCompletadas = () => {
        cambiarMostrarCompletadas(!mostrarCompletadas);
    }

    return(
        // Se estara trabajando con metodologia Benp
        <header className="header">
            <h1 className="header__titulo">Lista de Tareas</h1>
            {mostrarCompletadas ? 
                <button 
                    className="header__boton"
                    onClick={() => toggleCompletadas()}
                >
                    No mostrar completadas
                    <FontAwesomeIcon icon={faEyeSlash} className="header__icono-boton" />
                </button>
            :
                <button 
                    className="header__boton"
                    onClick={() => toggleCompletadas()}
                >
                    Mostrar completadas
                    <FontAwesomeIcon icon={faEye} className="header__icono-boton" />
                </button>
            }
        </header>
    );
}

export default Header;