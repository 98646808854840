import React, {useState} from 'react';
// Insertar el componente FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Importando dos tipos de iconos
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
// Importando UUID version 4
import { v4 as uuidv4 } from 'uuid';

// ****** //

const FormularioTareas = ({tareas, cambiarTareas}) => {
    const [inputTarea, cambiarInputTarea] = useState('');

    const handleInput = (e) => {
        cambiarInputTarea(e.target.value);
    }

    const handleSubmit = (e) => {
        // es una funcion ya que lleva ()
        e.preventDefault();

        cambiarTareas(
            [
                // esta instruccion significa que esta tomando todo el erreglo que ya
                // existe en archivo App.js y lo considere para luego en las otras {}-llaves
                // se incluira otras tareas con el boton de agregar
                ...tareas,
                {
                    id: uuidv4(),
                    texto: inputTarea,
                    completada: false
                }
            ]
        );
        cambiarInputTarea('');
    }

    return (
        <form action="" className="formulario-tareas" onSubmit={handleSubmit}>
            <input
                type="text"
                className="formulario-tareas__input"
                placeholder="Escribe una tarea"
                value={inputTarea}
                onChange={(e) => handleInput(e)}
            />
            <button 
                type="submit"
                className="formulario-tareas__btn"
            >
                <FontAwesomeIcon 
                    icon={faPlusSquare} 
                    className="formulario-tareas__icono-btn"
                />
            </button>
        </form>
    );
}

export default FormularioTareas;